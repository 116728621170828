<template>
  <div class="app-container property_box">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人、小规模纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr>
          <td>本期准予抵减税额</td>
          <qzf-str-td :colspan="5" :disabled="false" v-model:amount="list[0].bqzydjse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>本期减（免）税额</td>
          <qzf-str-td :colspan="5" :disabled="false" v-model:amount="list[0].bqjmse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>期初末缴税额</td>
          <qzf-str-td :colspan="5" :disabled="false" v-model:amount="list[0].qcwjse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>本期缴纳前期应纳税额</td>
          <qzf-str-td :colspan="5" :disabled="false" v-model:amount="list[0].bqjnqqyynse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>本期预缴税额</td>
          <qzf-str-td :colspan="5" :disabled="false" v-model:amount="list[0].bqyjse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>本期应补（退）税额</td>
          <qzf-str-td :colspan="5" :disabled="false" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>期末未缴税额</td>
          <qzf-str-td :colspan="5" :disabled="false" v-model:amount="list[0].qmwjse" @change="changeList()"></qzf-str-td>
        </tr>
      </table>

    </div>
    <div class="save_box">
      <el-button type="primary" @click="saveValues()" size="small" plain>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'xfsMain',
  props: {
    title: String,
  },
  data() {
    return {
      listQuery: {},
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      list:[{}],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 公式
    changeList(){

    },
    getList(){
      gsInfo({tableName: 'gs_xfs',itemName:'main'}).then(res=>{
        if(res.data.data.info){
          this.list = res.data.data.info
        }
      })
    },
    saveValues(){
      let param = {
        tableName:'gs_xfs',
        itemName:'main',
        items: this.list
      }
      gsEdit(param).then(res => {
        if(res.data.msg == "success") {
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}
.width_mini{
  width: 80px;
}
.width_small{
  width: 220px;
}
.width_big{
  width: 280px;
}
.width_date{
  width: 160px;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 9px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  span{
    line-height: 26px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 14px;
    text-align: center;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
  }
}
.close_item{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 0;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 50%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}

.big_box4{
  width: 98%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.addi{
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: -26px;
  font-size: 22px;
  color: $color-main;
}
.content tr td:first-child {
  width: 35%;
}
</style>