<template>
  <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="消费税">
      <!-- <div class="right_box">
        <el-button type="primary" @click="download()" size="small" plain>导出</el-button>
        <el-button size="small" @click="printingNew()" type="success" plain>打印</el-button>
      </div> -->
      <xfsMain title="消费税" ref="xfsMain"></xfsMain>
    </el-tab-pane>
    <el-tab-pane label="消费税附加资料（一）">
      <xfsFb1 title="消费税附加资料（一）" ref="xfsFb1"></xfsFb1>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import xfsMain from "./xfsMain.vue"
import xfsFb1 from "./xfsFb1.vue"
import { report } from "@/api/printSet"

export default {
  name:'xfs',
  components:{
    xfsMain,
    xfsFb1
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '小规模增值税及附加税费申报表'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '增值税及附加税费申报表（小规模适用）附加资料（一）'){
        this.$refs.fbOne.getList()
      } else if(tab.props.label == '增值税及附加税费申报表（小规模适用）附加资料（二）'){
        this.$refs.fbTwo.getList()
      }else if(tab.props.label == '增值税减免税申报明细表'){
        this.$refs.fbThree.getList()
      }
    },
    download(){

    },
    printingNew(){
      console.log(this.$store.getters['user/comInfo']);
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_xfs']
      }
      report(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 27px;
  right: 8px;
  z-index: 99;
}
</style>