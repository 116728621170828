<template>
  <div class="app-container property_box">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人、小规模纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%">
        <i @click="handleAddItem(list)" class="iconfont icon-jiahao addi"></i>
        <tr class="top_bg">
          <td class="center weight" style="width:36%">应税消费品名称</td>
          <td class="center weight" style="width:12.8%">适用税率</td>
          <td class="center weight" style="width:12.8%">计量单位</td>
          <td class="center weight" style="width:12.8%">销售数量</td>
          <td class="center weight" style="width:12.8%">销售额</td>
          <td class="center weight" style="width:12.8%">应纳税额</td>
        </tr>

        <tr v-for="(item,index) in list" :key="index" class="close_item">
          <td><input style="width:97%" type="text" v-model="item.name"></td>
          <!-- <qzf-str-td :disabled="false" v-model:amount="item.name" @change="changeList()"></qzf-str-td> -->
          <qzf-str-td :disabled="false" v-model:amount="item.sysl" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="item.jldw" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="item.xssl" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="item.xse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="item.ynse" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list,index)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr>
          <td>合计</td>
          <td>--</td>
          <td>--</td>
          <td>--</td>
          <td>{{totalAmount}}</td>
          <td>{{totalAmount2}}</td>
        </tr>
      </table>

    </div>
    <div class="save_box">
      <el-button type="primary" @click="saveValues()" size="small" plain>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'xfsFb1',
  props: {
    title: String,
  },
  data() {
    return {
      listQuery: {},
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      list:[]
    }
  },
  computed: {
    //金额合计
    totalAmount() {
      let totalAmount = 0;
      this.list.map(v => {
        if (v.xse) {
          totalAmount += Number(v.xse);
        }
      });
      return parseFloat(totalAmount.toFixed(2))
    },
    totalAmount2() {
      let ynseAmount = 0;
      this.list.map(v => {
        if (v.ynse) {
          ynseAmount += Number(v.ynse);
        }
      });
      return parseFloat(ynseAmount.toFixed(2))
    },
  },
  created() {
    this.getList()
  },
  methods: {
    // 公式
    changeList(){

    },
    // 主表添加一行
    handleAddItem(item) {
      item.push({
        name:'',
        sysl:'',
        jldw:'',
        xssl:'',
        xse:'',
        ynse:'',
      });
    },
    // 主表删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    getList(){
      gsInfo({tableName: 'gs_xfs',itemName:'fb1'}).then(res=>{
        console.log(res,'res')
        if(res.data.data.info){
          this.list = res.data.data.info
        }
      })
    },
    saveValues(){
      let param = {
        tableName:'gs_xfs',
        itemName:'fb1',
        items: this.list
      }
      gsEdit(param).then(res => {
        if(res.data.msg == "success") {
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.width_mini{
  width: 80px;
}
.width_small{
  width: 220px;
}
.width_big{
  width: 280px;
}
.width_date{
  width: 160px;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 9px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  span{
    line-height: 26px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 14px;
    text-align: center;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
  }
}
.close_item{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 0;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 50%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}

.big_box4{
  width: 98%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.addi{
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: -26px;
  font-size: 22px;
  color: $color-main;
}
.content tr td:first-child {
  width: 35%;
}
</style>